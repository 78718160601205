import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class AuthService {
  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  login({ identifier, password }) {
    return axiosService.axiosIns.post(this.jwtConfig.loginEndpoint, { identifier, password })
  }

  register(userData) {
    return axiosService.axiosIns.post(this.jwtConfig.registerEndpoint, { userData })
  }

  verifyAuthyCode(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.verifyAuthyCodeEndpoint, ...args)
  }

  resetPassword({ email }) {
    return axiosService.axiosIns.post(this.jwtConfig.resetPassword, { email })
  }

  recoveryPassword({ token, password }) {
    return axiosService.axiosIns.post(`${this.jwtConfig.recoveryPassword}/${token}`, { password })
  }

  forgotPassword({ email }) {
    return axiosService.axiosIns.post(this.jwtConfig.forgotPassword, { email })
  }
}

function useAuthService() {
  const authService = new AuthService()

  return {
    authService,
  }
}

const { authService } = useAuthService()
export default authService
